import { render, staticRenderFns } from "./SendTicketMessage.vue?vue&type=template&id=19c3f7e2&scoped=true&"
import script from "./SendTicketMessage.vue?vue&type=script&lang=js&"
export * from "./SendTicketMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c3f7e2",
  null
  
)

export default component.exports